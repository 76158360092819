.contactContainer {
    height: 100vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

h1.contactTitle {
    padding: 20px;
    letter-spacing: 20px;
    font-family: 'Stranger-Wide';
    color: rgb(237, 154, 31);
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

.contactAlert {
    color: rgb(237, 62, 31);
    text-shadow: 2px 2px rgb(237, 154, 31);
    margin-bottom: 0px;
}

.contactForm {
    height: 80vh;
    display: flex;
    justify-content: center;
    flex-direction: row;
    box-shadow: 5px 5px 0px rgb(237, 154, 31);
    border: 2px solid rgb(237, 154, 31);
    border-radius: 5px;
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
}

div.contactForm form {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    font-family: "Stranger-Slim";
    font-size: x-large;
}

div.contactForm form div {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    margin-top: auto;
    /* margin-bottom: auto; */
    flex-direction: column;
    width: 100%;
}

div.contactForm form input, div.contactForm form textarea {
    /* border-radius: 10px; */
    border: none;
    border-bottom: 2px solid rgb(237, 154, 31);
    margin: 10px;
    font-family: "Roestica";
    color: rgb(237, 154, 31);
    padding: 5px;
}

div.row div.nameEmail {
    display: flex;
    flex-direction: row;
}

div.row div.nameEmail input.name {
    margin-right: auto;
}

button.contactButton {
    border: 2px solid rgb(237, 154, 31);
    border-radius: 2px;
    margin: auto;
    color: rgb(237, 154, 31);
    background-color: transparent;
}

button.contactButton:hover {
    background-color: rgb(237, 154, 31);
    color: rgb(255, 255, 255);
}

div.contactResume {
    height: 80vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    font-family: "Stranger-Slim";
    box-shadow: 5px 5px 0px rgb(237, 154, 31);
    border: 2px solid rgb(237, 154, 31);
    border-radius: 5px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

div.contactResume div.row {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
}

div.contactInfo {
    padding: 20px;
    width: 50%;
    text-align: center;
}

div.resumeFile {
    padding: 20px;
    width: 50%;
}

div.resumeOverlay h2 {
    display: none;
}

img.resumeImg {
    width: 100%;
    object-fit: cover;
    object-position: top;
    box-shadow: 5px 5px 0px rgb(120, 122, 108);
    border: 2px solid rgb(120, 122, 108);
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

img.resumeImg:hover {
    box-shadow: 8px 8px 0px rgb(120, 122, 108);
}

img.socialIcons {
    width: 30%;
    margin: 10px;
    box-shadow: 3px 3px 0px rgb(237, 154, 31);
    border: 2px solid rgb(237, 154, 31);
    border-radius: 50%;
}

img.socialIcons:hover {
    box-shadow: 6px 6px 0px rgb(237, 154, 31);
}

@media  screen and (max-width: 1300px) {
    .contactContainer {
        height: 140vh;
        margin-bottom: 20px;
    }
    div.contactForm {
        width: 90%;
        height: 70vh;
        margin-bottom: 10px;
    }
    div.contactInfo {
        width: 50%;
        margin-top: auto;
        margin-bottom: auto;
    }
    div.contactResume {
        width: 90%;
        height: 50vh;
        margin-top: 10px;
    }

    div.contactRow {
        width: 100%;
    }

    div.resumeFile {
        padding: 0px;
        padding-left: 0px;
        padding-right: 0px;
        width: 50%;
        margin-top: auto;
        margin-bottom: auto;
    }
    img.resumeImg {
        margin:auto;
        width: 90%;
        object-fit: cover;
        height: 200px;
    }
    img.socialIcons {
        width: 60px;
        margin: 0px;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: 3px 3px 0px rgb(237, 154, 31);
        border: 2px solid rgb(237, 154, 31);
        border-radius: 50%;
    }
    div.resumeFile a {
        text-decoration: none;
    }
    div.resumeOverlay h2 {
        font-size: 30px;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        color: rgb(2, 2, 2);
    }
}
@media screen and (max-width: 991px) {
    .contactContainer {
        height: 150vh;
        margin-bottom: 20px;
    }
    div.contactForm {
        height: 65vh;
        padding: 0px;
        margin-bottom: 20px;
    }
    div.contactForm form textarea {
        height: 200px;
    }
    div.contactRow {
        width: 100%;
    }
    div.contactResume {
        height: 65vh;
        margin-top: 10px;
    }
    div.contactInfo {
        width: 100%;
        margin-top: 0px;
    }
    div.resumeFile {
        padding: 0px;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
    }
    div.resumeFile a {
        text-decoration: none;
    }
    img.resumeImg {
        width: 90%;
        object-fit: cover;
        height: 200px;
    }
    img.socialIcons {
        width: 50px;
        margin: 0px;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: 3px 3px 0px rgb(237, 154, 31);
        border: 2px solid rgb(237, 154, 31);
        border-radius: 50%;
    }
    div.resumeOverlay h2 {
        font-size: 30px;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        color: rgb(2, 2, 2);
    }
}

@media screen and (max-width: 500px) {
    h1.contactTitle {
        padding: 0px;
        padding-top: 20px;
        font-size: x-large;
    }
    .contactContainer {
        height: 120vh;
    }
    h2.contactHeader {
        padding-top: 20px;
    }
    div.contactForm {
        height: 60vh;
        padding: 0px;
        margin-bottom: 10px;
    }
    div.contactForm form {
        flex-direction: column;
        font-size: large;
    }
    div.contactForm form input, div.contactForm form textarea {
        margin-top: 5px;
        font-size: medium;
    }
    div.contactForm form textarea {
        height: 15vh;
    }
    div.contactResume {
        height: 45vh;
        margin-top: 10px;
    }
    div.contactRow {
        height: 100%;
        width: 100%;
    }
    div.contactInfo {
        width: 100%;
        margin: auto;
    }
    div.contactInfo h3 {
        font-size: medium;
        text-align: center;
    }
    div.resumeFile {
        padding: 0px;
        margin: auto;
        width: 100%;
    }
    img.socialIcons {
        width: 40px;
       
    }
    img.resumeImg {
        width: 90%;
        height: 12vh;
        margin-top: 0px;
        object-fit: cover;
        box-shadow: 5px 5px 0px rgb(120, 122, 108);
        border: 2px solid rgb(120, 122, 108);
        border-radius: 5px;
    }
    div.resumeOverlay {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    div.resumeOverlay a {
        background-color: rgb(75, 75, 75, 0.6);
        width: 75%;
        height: 12vh;
        position: absolute;
        border-radius: 5px;
        z-index: 2;
    }
    div.resumeOverlay h2 {
        font-size: 40px;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        color: rgb(255, 255, 255);
    }
}

@media screen and (max-width: 280px) {
    h1.contactTitle {
        font-size: large;
    }
    img.socialIcons {
        width: 5vh;
    }
    div.resumeOverlay {
        width: 76%;
    }
    div.resumeOverlay h2 {
        font-size: 30px;
    }
}