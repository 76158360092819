@font-face {
  font-family: 'Neon';
  src: local('Neon'), url(../fonts/Neoneon-3zaD6.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Archive';
  src: local('Archive'), url(../fonts/Archive-Regular.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Stranger-wide';
  src: local('Stranger-wide'), url(../fonts/Stranger-Wide.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Roestica';
  src: local('Roestica'), url(../fonts/Roestica-Regular.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

div.aboutContainer {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  scroll-snap-align: start;
}

h1.aboutTitle {
  font-family: "Stranger-Wide";
  text-align: center;
  color: rgb(237, 154, 31);
  padding: 20px;
  margin-bottom: auto;
}

div.aboutRow {
  margin-top: auto;
  height: 85vh;
  width: 90%;
  margin: 20px;
}
div.aboutDiv {
  overflow: auto;
}
p.aboutParagraph {
  display: block;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  text-align: left;
}

img.aboutImage {
  width: auto;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 50vh;
  box-shadow: 5px 5px 0px rgb(237, 154, 31);
  border-radius: 5px;
}

@media screen and (max-width: 991px) {
  div.aboutContainer {
    height: 70vh;
  }
  div.aboutRow {
    width: 100%;
    overflow: auto;
  }
  
  img.aboutImage {
    margin: 0px;
    height: 30vh;
    position: relative;
    top: 0;
    margin-right: 20px;
    margin-bottom: 20px;
    /* margin-left:10px; */
  }
}

@media screen and (max-width: 500px) {
  div.aboutContainer {
    height: 90vh;
  }
  div.aboutRow {
    width: 100%;
  }
  h1.aboutTitle {
    padding: 0px;
    padding-top: 20px;
    font-size: x-large;
  }
  img.aboutImage {
    margin: 0px;
    height: 25vh;
    position: relative;
    top: 0;
    margin-right: 20px;
    margin-bottom: 10px;
    /* margin-left:10px; */
  }
}