
/* html
body {
  background-color: rgb(77, 80, 97);
} */
.container {
  scroll-snap-type: mandatory;
}

section.child {
  scroll-snap-align: start;
}


