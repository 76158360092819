/* ------------------------------Modal Styles----------------------- */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  overflow: auto;
  padding-bottom: 100px;
}

.modalCarousel {
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: 10%;
  width: 70vw;
}

img.modalImage {
  flex-direction: row;
  margin-right: auto;
  margin-left: auto;
  height: 15vh;
  width: 15vh;
  object-fit: cover;
  background-position: center;
  cursor: pointer;
  z-index: 2;
  padding: 5px;
}

img.displayModal {
  display: flex;
  margin-top: 2%;
  margin-right: auto;
  margin-left: auto;
  height: 75vh;
  background-position: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
}

div.displayWrap {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  width: 60vw;
  overflow: auto;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 10px;
}

h2.modalCancel {
  position: absolute;
  background-color: rgba(255, 255, 255);
  padding: 5px;
  font-size: medium;
  border-radius: 10px;
  margin-right: 10%;
  margin-top: 5%;
  right: 0;
  z-index: 4;
  cursor: pointer;
  color: rgb(2, 2, 2)
}

@media screen and (max-width: 500px) {
  img.displayModal {
    display: flex;
    max-width: 90vw;
    height: 70vh;
    margin-top: 5%;
    object-fit: contain;
    cursor: pointer;
    z-index: 2;
    position: relative;
  }
  div.mobileContainer {
    margin-top: auto;
    height: 80vh;
  }
  img.modalImage {
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
    padding: 5px;
    height: 15vh;
    width: 15vh;
    object-fit: cover;
    background-position: center;
    cursor: pointer;
    z-index: 2;
    position: relative;
  }
  h2.modalCancel {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px;
    font-size: medium;
    border-radius: 10px;
    margin-top: 40px;
    margin-right: 10px;
    right: 0;
    z-index: 4;
    cursor: pointer;
    color: rgb(2, 2, 2)
  }
  div.displayWrap {
    width: 75vw;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 10px;
  }
}