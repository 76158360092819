@font-face {
    font-family: 'Neon';
    src: local('Neon'), url(../fonts/Neoneon-3zaD6.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Archive';
    src: local('Archive'), url(../fonts/Archive-Regular.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Stranger-Wide';
    src: local('Stranger-Wide'), url(../fonts/Stranger-Wide.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Stranger-slim';
    src: local('Stranger-slim'), url(../fonts/Stranger-Slim.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Stranger-inline';
    src: local('Stranger-inline'), url(../fonts/Stranger-Inline.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Roestica';
    src: local('Roestica'), url(../fonts/Roestica-Regular.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

h1.webDevTitle {
    color: rgb(237, 154, 31);
    font-family: 'Stranger-Wide';
    padding: 20px;
}

.webDevContainer {
    height: 110vh;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    scroll-snap-align: start;
}


.webDevWrapper {
    height: 95vh;
    display: flex;
    flex-direction: column;
}


div.webDevMainDisplay {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;

}

div.carouselStylingWeb {
    display: flex;
    justify-content: center;
}

img.WebDevImages {
    height: 65vh;
    width: 100%;
    object-fit: cover;
    object-position: top;
    border: 5px;
    border-top-right-radius: 20px;
    cursor: pointer;
    box-shadow: 5px 5px 0px rgb(237, 154, 31);
    /* border: 2px solid rgb(237, 154, 31); */
    border-radius: 5px;
   
}

div.imgColumn {
    margin-left: auto;
    margin-right: auto;
}

img.WebDevImages:hover {
    /* border: 2px solid rgb(237, 154, 31); */
    box-shadow: 10px 10px 0px rgb(237, 154, 31);
}

div.webAppInfo {
    display: flex;
    height: 65vh;
    width: 36%;
    flex-direction: column;
    justify-content: right;
   
    color: rgb(75, 75, 75);
    box-shadow: 5px 5px 0px rgb(120, 122, 108);
    border: 2px solid rgb(120, 122, 108);
    border-radius: 5px;
    margin-right: auto;
   
}
div.descriptionWrap {
    height:100%;
    overflow: auto;
}


h2.projectTitle {
    font-family: 'Stranger-slim';
    width: 100%;
    background-color: rgb(120, 122, 108);
    border-bottom: 2px solid rgb(120, 122, 108);
    padding: 5px;
    color: rgb(255, 255, 255);
}

p.webAppDesc {
    text-align: justify;
    font-family: "Roestica";
    margin: 20px;
}

div.groupProjectWrapper {
    text-align: center;
    width: 100%;
    
}
h3.groupProjectTitle,
p.groupProjectSubtitle {
    font-family: "Stranger-Slim";
}
h3.groupProjectTitle {
    margin-bottom: auto;
}

p.groupProjectSubtitle {
    font-size: medium;
    text-align: center;
    margin-top: 0px;
}

ul.groupProjectList {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
}
li.groupProjectItem {
   font-family: "Roestica";
   font-size: medium;
   padding: 5px;
   margin-right: auto;
   text-align: left;
    width:45%;
}
ul.webAppLinks {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    list-style: none;
}

ul.webAppLinks li a {
    text-align: center;
    font-family: "Stranger-slim";
    color: rgb(237, 154, 31);
    margin-right: 20px;
}



div.projectSelector {
    width: 90vw;
 margin:auto;
}
div.carouselSelectorWrap{
    
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: auto;
    box-shadow: 5px 5px 0px rgb(237, 154, 31);
    border: 2px solid rgb(237, 154, 31);
    border-radius: 3px;
  }
  

  h2.devProjectsTitle {
    color: rgb(75, 75, 75);
   
    font-family: "Stranger-slim";
    
  

}
div.imageWrap {
 padding:7px;
    margin-right: auto;
    margin-left: auto;
}

p.carouselTitle {
    color: rgb(237, 154, 31);
    margin: 0px;
    text-align: center;
    
}
  img.carouselSelector {
  
    height: 15vh;
    width: 20vh;
   object-fit: cover;
    object-position: top;
    cursor: pointer;
    box-shadow: 2px 2px 0px rgb(237, 154, 31);
    border-radius: 2px;
   
  }
  img.carouselSelector:hover {

    box-shadow: 5px 5px 0px rgb(237, 154, 31);
}

@media screen and (max-width: 992px) {
    div.carouselStylingWeb {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    div.webDevMainDisplay {
        margin-top: 0px;
        margin-bottom: 0px;
       
    }
    .webDevContainer {
        height: 110vh;
    }
    .webDevWrapper {
        height: 95vh;
        flex-direction: column;
    }
    div.imgColumn {
        margin-bottom:20px;
        width: 100%;
    }
    img.WebDevImages {
        height: 35vh;
        width: 100%;
    }
    div.webAppInfo {
        height: 30vh;
       width: 100%;
      margin-top: auto;
      margin-bottom: auto;
    }
    div.descriptionWrap {
        margin-top: 10px;
        height: 50vh;
        overflow: auto;
        margin-right: 20px;
        margin-left: 20px;
    }
    p.webAppDesc {
        margin-right: 10px;
        margin-left: 0;
        font-size: 15px;
    }
    p.techItem {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    div.projectSelector {
        width: 80%;
        height: auto; 
       margin-top: 0px;
       margin-bottom: auto;
    }
    div.carouselSelectorWrap{
        margin-top: auto;
        margin-left: 0px;
        margin-right: 0px;
        flex-direction: row;
        height: auto;
        width: auto;
        padding: 5px
       
      }
      img.carouselSelector {
        flex-direction: row;
        margin-right: auto;
        margin-left: auto;
        height: 13vh;
       width: 20vh;
        padding: 0px;
        object-fit: cover;
        object-position: top;
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
        
      }
}

@media screen and (max-width: 500px) {
    h1.webDevTitle {
        color: rgb(237, 154, 31);
        font-family: 'Stranger-Wide';
        padding: 0px;
        padding-top: 20px;
        font-size: x-large;
    }
    .webDevContainer {
        height: 100vh;
    }
   
    .webDevWrapper {
        height: 95vh;
        flex-direction: column;
    }
    div.carouselStylingWeb {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    div.imgColumn {
        margin-left: auto;
        margin-right: auto;
       
        height: 25vh;
        margin-bottom: 15px;
    }
    img.WebDevImages {
        height: 25vh;
        width: 100%;
    }
    div.webAppInfo {
        height: 35vh;
        margin-right: auto;
        margin-left: auto;
        margin-top: 0px;
        margin-bottom: 8px;
    }
    div.descriptionWrap {
        margin-top: 10px;
        height: 20vh;
        overflow: auto;
        margin-right: 10px;
        margin-left: 10px;
       
    
    }
    p.webAppDesc {
        margin-left: 5px;
        font-size: 15px;
        text-align: justify;
    }
    ul.webAppLinks {
        height: 10%;
    }
    h1.webDevTitle {
        letter-spacing: 10px;
    }
    div.techList {
        padding: 10px;
        padding-bottom: 10px;
    }
    p.featSubTitle {
        margin: 0px;
    }

    div.projectSelector {
        width: 90vw;
        height: auto; 
        margin-bottom: 20px;
    
    }
    h2.devProjectsTitle {
        color: rgb(75, 75, 75);
        font-family: "Stranger-slim";
    }

    div.carouselSelectorWrap{
        
        margin-left: auto;
        margin-right: auto;
        flex-direction: row;
        height: auto;
        width:auto;
        padding: 5px
       
      }
      div.carouselSelectorWrap div {
          padding: 5px;
      }
      img.carouselSelector {
        flex-direction: row;
        margin-right: auto;
        margin-left: auto;
        
        width: 20vh;
        padding: 0px;
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
        
        border:1px solid rgb(237, 154, 31);
        border-radius: 5px;
      }

    div.webTechnologies {
        display: block;
        height: 20vh;
    }
}

@media screen and (max-width: 280px) {
    h1.webDevTitle {
        font-size: large;
    }
}